import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import repairIcon from '../images/new-repair-icon-rebrand.svg';
import { useActionCreators } from '../core/useActionCreators';
import * as TMTActionCreators from '../actionCreators/tmt';
import { useInterval } from '../core/useInterval';
import TechProgressText from '../components/TechProgressText';
import '../css/fontstyle.css';
import {
  ProgressBar,
  AppWarning,
  Footer,
  SwitchDisplay,
  LoadingScreen
} from '../components';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  qsParams: { sId: string };
}

export function TrackMyTech(props: Props) {
  const classes = useStyles(props);
  const updateTMTStateAction = useActionCreators(
    TMTActionCreators.updateTMTState
  );

  useInterval(() => {
    //plugin SJNumber
    updateTMTStateAction(props.qsParams.sId);
  }, 120000);

  const { root, appContainer, centerContainer } = classes;

  const techStatus = useSelector(
    (state: RootState) => state.tmt.technicianStatusDetails.techStatus
  );

  const appLoading = useSelector((state: RootState) => state.tmt.appLoading);

  return (
    <div className={root}>
      {!appLoading && <AppWarning techStatus={techStatus} />}
      <main className={appContainer}>
        <img src={repairIcon} alt="repairIcon"></img>
        <Typography variant="h4">Track my technician</Typography>
        {!appLoading ? (
          <>
            <ProgressBar techStatus={techStatus} />
            <Paper className={centerContainer} elevation={0}>
              <TechProgressText techStatus={techStatus} />
              <SwitchDisplay techStatus={techStatus} />
            </Paper>
          </>
        ) : (
          <LoadingScreen />
        )}
      </main>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    textAlign: 'center',
    fontFamily:'apercu pro !important'
  },
  appContainer: {
    fontFamily: 'apercu pro !important'
  },
  centerContainer: {
    fontFamily: 'apercu pro !important',
    padding: '10px 16px',
    maxWidth: 650,
    margin: '0 auto',
    border: '1px solid #D5D6DA',
    marginTop:20,
    borderRadius: 6,
    [theme.breakpoints.up('sm')]: {
      padding: '10px 30px'
    }
  },
  loadingIcon: {
    margin: '30px 0',
    fontFamily: 'apercu pro !important'
  }
}));