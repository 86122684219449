import * as React from 'react';
import {
  CancelDisplay,
  MainDisplay,
  DepartedDisplay,
  ErrorDisplay
} from '../components';
import { TechnicianStatus } from '../model';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

export function SwitchDisplay(props: TechnicianStatus) {
  const { techStatus } = props;

  const spDetails = useSelector(
    (state: RootState) => state.tmt.serviceProviderDetails
  );

  const { serviceProviderName, serviceProviderPhone } = spDetails;

  switch (techStatus) {
    case 'EnRoute':
    case 'Arrived':
      return <MainDisplay techStatus={techStatus} />;
    case 'Departed':
      return (
        <DepartedDisplay
          serviceProviderName={serviceProviderName}
          serviceProviderPhone={serviceProviderPhone}
        />
      );
    case 'Canceled':
      return (
        <CancelDisplay
          serviceProviderName={serviceProviderName}
          serviceProviderPhone={serviceProviderPhone}
        />
      );
  }
  return (
    <ErrorDisplay
      serviceProviderName={serviceProviderName}
      serviceProviderPhone={serviceProviderPhone}
    />
  );
}
