import { TMTActions, Action } from '../model';
import createReducer from '../core/createReducer';
import { TMTState } from '../model';

export const tmt = createReducer<TMTState>(
  {
    technicianStatusDetails: {
      techStatus: '',
      techArrivalTime: ''
    },
    serviceProviderDetails: {
      serviceProviderName: '',
      serviceProviderPhone: ''
    },
    techDetails: {
      technicianImage: '',
      technicianName: '',
      badgeNumber: '',
      badgeExpiration: '',
      checkBadgeLink: ''
    },
    travelDetails: {
      estimatedTravelTime: '',
      techDepartureTime: ''
    },
    appointmentDetails: {
      appointmentDate: '',
      appointmentTime: '',
      appointmentLocation: ''
    },
    techLocation: {
      currentTechLocation: {
        lat: null,
        lng: null
      }
    },
    appLoading: null
  },
  {
    [TMTActions.UPDATE_APPOINTMENT_DETAILS](state: TMTState, action: Action) {
      return { ...state, appointmentDetails: action.payload };
    },
    [TMTActions.UPDATE_SERVICE_PROVIDER_DETAILS](
      state: TMTState,
      action: Action
    ) {
      return {
        ...state,
        serviceProviderDetails: {
          ...state.serviceProviderDetails,
          ...action.payload
        }
      };
    },
    [TMTActions.UPDATE_TECH_DETAILS](state: TMTState, action: Action) {
      return {
        ...state,
        techDetails: { ...state.techDetails, ...action.payload }
      };
    },
    [TMTActions.UPDATE_TECH_LOCATION](state: TMTState, action: Action) {
      return {
        ...state,
        techLocation: action.payload
      };
    },
    [TMTActions.UPDATE_TECH_STATUS](state: TMTState, action: Action) {
      return {
        ...state,
        technicianStatusDetails: {
          ...state.technicianStatusDetails,
          ...action.payload
        }
      };
    },
    [TMTActions.SET_ESTIMATED_ARRIVAL_TIME](state: TMTState, action: Action) {
      return {
        ...state,
        travelDetails: {
          ...state.travelDetails,
          ...action.payload
        }
      };
    },
    [TMTActions.SET_APP_LOADING](state: TMTState, action: Action) {
      return {
        ...state,
        appLoading: action.payload
      };
    }
  }
);
