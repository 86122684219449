import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export function useActionCreators<P>(actionCreators: P, deps?: any): P {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actionCreators)) {
      return actionCreators.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actionCreators as any, dispatch);
  }, [actionCreators, dispatch]);
}