import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { TechnicianStatus } from '../model';
import rightTickImage from '../images/right-tick.svg';
export function ProgressBar(props: TechnicianStatus) {
  const classes = useStyles();
  const options: { name: string; key: string; step: string }[] = [
    {
      name: 'En Route',
      key: 'EnRoute',
      step: ''
    },
    {
      name: 'Arrived',
      key: 'Arrived',
      step: ''
    },
    {
      name: 'Departed',
      key: 'Departed',
      step: ''
    }
  ];

  const findStatusIndex = (status: string): number =>
    options.findIndex(({ key }: { key: string }) => key === status);

  const { techStatus }: { techStatus: string } = props;
  const {
    container,
    active,
    numberCircle,
    disabled,
    divider,
    circleContainer,
    textSpan
  } = classes;

  if (techStatus === 'Canceled' || !techStatus) {
    return null;
  }
  return (
    <div className={container}>
      <div className={divider}></div>
      {options.map(
        ({ name, key, step }: { name: string; key: string; step: string }) => (
          <div
            key={key}
            className={`
            ${circleContainer} 
            ${findStatusIndex(key) <= findStatusIndex(techStatus) ? active : ''}
            ${
              findStatusIndex(key) < findStatusIndex(techStatus) ? disabled : ''
            }
          `}
          >
            <div className={numberCircle}>{step}</div>
            <div className={textSpan}>{name}</div>
          </div>
        )
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily:'apercu pro !important',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    maxWidth: 345,
    margin: '0 auto 30px auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 475,
      margin: '0 auto 40px auto'
    },
    '& span': {
      marginTop: 10,
      display: 'inline-block',
      fontSize: '16px !important'
    }
  },
  active: {
    '& $numberCircle': {
      backgroundColor: '#000',
      backgroundImage:`url(${rightTickImage})`,
      backgroundRepeat:'no-repeat',
      backgroundPosition:'center'
    },
    '& span': {
      fontWeight: 600
    },
    fontWeight:700
  },
  disabled: {
    '& span': {
      color: '#60676C',
      fontWeight: 'normal'
    }
  },
  circleContainer: {
    zIndex: 2
  },
  numberCircle: {
    fontWeight: 'bold',
    fontSize: '16px !important',
    lineHeight: '20px',
    border: '2px solid #6E767D',
    borderRadius: '50%',
    width: '20px !important',
    height: '20px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    backgroundColor: '#fafafa',
    minHeight:'20px !important'
  },
  divider: {
    background: '#6E767D',
    width: '210px',
    position: 'absolute',
    zIndex: 0,
    height: '2px !important',
    top: 12,
    [theme.breakpoints.up('sm')]: {
      width: 310
    }
  },
  textSpan:{
    marginTop: 10,
      display: 'inline-block',
      fontSize: '16px !important'
  }
}));
