import moment from 'moment';
import {
  TMTAction,
  TMTActions,
  TechDetails,
  TechLocation,
  AppointmentDetails,
  ServiceProviderDetails,
  TechnicianStatus,
  EstArrivalTime
} from '../model';
import { acceptedTechStatuses } from '../utils/acceptedTechStatuses';
import { formatPhoneNumber } from '../utils';
import { TMTService } from '../services';

const updateTMTState = (sId: string) => {
  return async (dispatch: Function, getState: Function) => {
    dispatch(setAppLoading(true));
    const tmtDetails = await TMTService.getTechDetails(sId).catch(e => {
      dispatch(setAppLoading(false));
    });

    if (tmtDetails) {
      const apptDate = ((tmtDetails || {}).appointmentDetails || {})
        .appointmentDate;
      const apptTime = ((tmtDetails || {}).appointmentDetails || {})
        .appointmentTime;
      const apptTimeMoment = apptDate
        ? moment(apptDate).add({
            hours: parseInt(apptTime.substring(0, 2)),
            minutes: parseInt(apptTime.substring(2, 4))
          })
        : undefined;
      const appointmentLocation = ((tmtDetails || {}).appointmentDetails || {})
        .appointmentLocation;

      const apptLocation = appointmentLocation
        ? `${appointmentLocation.addressLine1 +
            (appointmentLocation.addressLine2
              ? appointmentLocation.addressLine2 + ' '
              : '')} ${appointmentLocation.cityStateZip}`
        : '';
      await dispatch(
        updateAppointmentDetails({
          appointmentDate: apptTimeMoment
            ? apptTimeMoment.local().format('MMMM D, YYYY')
            : 'Unknown',
          appointmentTime: apptTimeMoment
            ? `Between ${apptTimeMoment.local().format('h:mma')} – ${moment(
                apptTimeMoment
              )
                .add(2, 'hours')
                .format('h:mma')}`
            : 'Unknown',
          appointmentLocation: apptLocation
        })
      );
      const { latitude, longitude, ...technicianDetails } =
        (tmtDetails || {}).technicianDetails || {};

      await dispatch(
        updateTechLocation({
          currentTechLocation: latitude
            ? {
                lat: latitude,
                lng: longitude
              }
            : {}
        })
      );

      await dispatch(updateTechDetails(technicianDetails));
      const technicianStatus =
        ((tmtDetails || {}).technicianStatus || {}).status || '';

      await dispatch(
        updateTechStatus({
          techStatus: acceptedTechStatuses.includes(technicianStatus)
            ? technicianStatus
            : ''
        })
      );

      if (technicianStatus === 'Arrived') {
        const arrivalTime =
          ((tmtDetails || {}).technicianStatus || {}).arrived || '';
        await dispatch(
          updateTechStatus({
            techStatus: 'Arrived',
            techArrivalTime: moment(arrivalTime)
              .local()
              .format('h:mma')
          })
        );
      }

      const serviceProviderName =
        (tmtDetails || {}).serviceProviderDetails.serviceProviderName || '';

      const serviceProviderPhone =
        (tmtDetails || {}).serviceProviderDetails.serviceProviderPhone || '';

      await dispatch(
        updateServiceProviderDetails({
          serviceProviderName,
          serviceProviderPhone: formatPhoneNumber(serviceProviderPhone)
        })
      );

      await dispatch(
        setEstimatedTravelTime({
          techDepartureTime: moment
            .utc()
            .local()
            .format()
        })
      );
      dispatch(setAppLoading(false));
    } else {
      dispatch(setAppLoading(false));
    }
  };
};

export const calculateArrivalTime = (travelTime: google.maps.Duration) => {
  return async (dispatch: Function, getState: Function) => {
    const { techDepartureTime } = getState().tmt.travelDetails;

    const arrivalTimeStart = moment
      .utc(techDepartureTime)
      .local()
      .add(travelTime.value, 'seconds')
      .format('h:mma');

    const arrivalTimeEnd = moment(arrivalTimeStart, 'h:mma')
      .add(20, 'minutes')
      .format('h:mma');

    const displayTime = `${arrivalTimeStart} - ${arrivalTimeEnd}`;

    dispatch(
      setEstimatedTravelTime({
        estimatedTravelTime: displayTime
      })
    );
  };
};

const updateTechDetails = (techDetails: TechDetails): TMTAction => {
  return {
    type: TMTActions.UPDATE_TECH_DETAILS,
    payload: techDetails
  };
};

const updateTechLocation = (techLocation: TechLocation): TMTAction => {
  return {
    type: TMTActions.UPDATE_TECH_LOCATION,
    payload: techLocation
  };
};

const updateAppointmentDetails = (
  apptDetails: AppointmentDetails
): TMTAction => {
  return {
    type: TMTActions.UPDATE_APPOINTMENT_DETAILS,
    payload: apptDetails
  };
};

const updateServiceProviderDetails = (
  spDetails: ServiceProviderDetails
): TMTAction => {
  return {
    type: TMTActions.UPDATE_SERVICE_PROVIDER_DETAILS,
    payload: spDetails
  };
};

const updateTechStatus = (technicianStatus: TechnicianStatus): TMTAction => {
  return {
    type: TMTActions.UPDATE_TECH_STATUS,
    payload: technicianStatus
  };
};

const setEstimatedTravelTime = (
  estimatedTravelTime: EstArrivalTime
): TMTAction => {
  return {
    type: TMTActions.SET_ESTIMATED_ARRIVAL_TIME,
    payload: estimatedTravelTime
  };
};

const setAppLoading = (bool: boolean) => ({
  type: TMTActions.SET_APP_LOADING,
  payload: bool
});

export { updateTMTState };
