import React, { useEffect, useState } from 'react';

export const RefreshTimer = ({ seconds }: { seconds: number }) => {
  const [timeLeft, setTimeLeft]: [number, Function] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) setTimeLeft(120);

    const intervalId: any = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes: number = Math.floor(timeLeft / 60);
  const showSeconds: number = timeLeft - minutes * 60;

  return (
    <>
      {minutes}:{showSeconds}
    </>
  );
};
