import appConfig from '../config.json';

import { v1 as uuid } from 'uuid';

export class BaseService {
  protected static async fetch(
    url: RequestInfo,
    options: RequestInit
  ): Promise<any> {
    let baseOptions: RequestInit = {};
    baseOptions.headers = {
      correlationId: uuid(),
      username: 'TrackMyTech',
      companyId: '-1',
      sender: 'TrackMyTech',
      'Content-Type': 'application/json'
    };

    const resp = await fetch(
      appConfig.apiBaseUrl + url,
      Object.assign({}, baseOptions, options, {
        headers: Object.assign({}, baseOptions.headers, options.headers)
      })
    );
    if (resp.ok) {
      return resp.json();
    }
  }
}
