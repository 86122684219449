import { BaseService } from './baseService';

const serviceEndpoints = {
  getTechDetails: '/operations/technician/v1/trackmytech/details'
};

export class TMTService extends BaseService {
  static async getTechDetails(sId: string) {
    return this.fetch(`${serviceEndpoints.getTechDetails}`, {
      method: 'GET',
      headers: {
        sessionId: sId
      }
    });
  }
}
