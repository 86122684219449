import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { RefreshTimer } from '../components';

export function TimeOfArrivalText(props: any) {
  const classes = useStyles();

  const timeOfArrival = useSelector(
    (state: RootState) => state.tmt.travelDetails.estimatedTravelTime
  );

  const { container, underline,textSize } = classes;
  return (
    <div className={container}>
      <p>
        <span className={textSize}>Estimated time of arrival: </span>{' '}
        {timeOfArrival ? timeOfArrival : 'unknown'}
      </p>
      <p className={underline}>
        Map refresh in: <RefreshTimer seconds={120} />
      </p>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#333F48',
    borderRadius: '0px 0px 6px 6px',
    padding: '10px 6px',
    textAlign: 'left',
    '& p': {
      color: 'white',
      fontSize: 14,
      margin: 0,
      fontWeight: 500,
      lineHeight: '18px'
    },
    '& span': {
      fontWeight: 400
    },
    '& $underline': {
      fontWeight: 700,
      paddingTop: 6
    }
  },
  underline: {
    textDecoration: 'underline'
  },
  textSize:{
    fontSize:'14px !important',

  }
}));
