interface TMTStateInterface {
  techDetails?: (TechDetails & TechLocation) | undefined;
  serviceProviderDetails?: ServiceProviderDetails | undefined;
  appointmentDetails?: AppointmentDetails | undefined;
  technicianStatusDetails?: TechnicianStatus | undefined;
  travelDetails?: EstArrivalTime | undefined;
  techLocation?: TechLocation | undefined;
  appLoading?: boolean | undefined;
}

export type TMTState = { [key: string]: any } | TMTStateInterface;

export interface TechDetails {
  technicianImage?: string;
  technicianName: string;
  badgeNumber?: string;
  badgeExpiration?: string;
  checkBadgeLink?: string;
  technicianMobileNumber : string;
}

export interface ServiceProviderDetails {
  serviceProviderName: string;
  serviceProviderPhone: string;
}

export interface AppointmentDetails {
  appointmentDate: string;
  appointmentTime: string;
  appointmentLocation: string;
}

export interface TechLocation {
  currentTechLocation?: object;
}

export interface TechnicianStatus {
  techStatus: string;
  techArrivalTime?: string; // the time the tech actually arrived
}

export interface EstArrivalTime {
  estimatedTravelTime?: string;
  techDepartureTime?: string; // the time tech leaves for service location
}

export enum TMTActions {
  UPDATE_TECH_DETAILS = 'UPDATE_TECH_DETAILS',
  UPDATE_SERVICE_PROVIDER_DETAILS = 'UPDATE_SERVICE_PROVIDER_DETAILS',
  UPDATE_APPOINTMENT_DETAILS = 'UPDATE_APPOINTMENT_DETAILS',
  UPDATE_TECH_LOCATION = 'UPDATE_TECH_LOCATION',
  UPDATE_TECH_STATUS = 'UPDATE_TECH_STATUS',
  SET_ESTIMATED_ARRIVAL_TIME = 'SET_ESTIMATED_ARRIVAL_TIME',
  SET_APP_LOADING = 'SET_APP_LOADING'
}

export type TMTPayloadType =
  | TechDetails
  | TechLocation
  | AppointmentDetails
  | ServiceProviderDetails
  | TechnicianStatus
  | EstArrivalTime;

export interface TMTAction {
  type: TMTActions;
  payload:
    | AppointmentDetails
    | ServiceProviderDetails
    | TechDetails
    | TechLocation
    | TechnicianStatus
    | EstArrivalTime;
}
