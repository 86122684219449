import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import {
  AppointmentDetailsDisplay,
  TechArrivalDisplay,
  TechDetailsDisplay,
  TimeOfArrivalText,
  GoogleMapContainer
} from '../components';
import { TechnicianStatus } from '../model';

export function MainDisplay(props: TechnicianStatus) {
  const classes = useStyles(props);
  const { techStatus } = props;
  const {
    mapFlex,
    flexHolder,
    techLocationHeader,
    pageBottom,
    textSize
  } = classes;

  return (
    <>
      <div className={mapFlex}>
        <div className={flexHolder}>
          <p
            className={techLocationHeader}
          >
            Your tech's location
          </p>

          {techStatus === 'Arrived' ? (
            <TechArrivalDisplay />
          ) : (
            <>
              <GoogleMapContainer />
              <TimeOfArrivalText />
            </>
          )}
        </div>
        {/* <RefreshButton className={hideButton} /> */}
        <div className={flexHolder}>
          <TechDetailsDisplay />
        </div>
      </div>
      {/* <RefreshButton className={showButton} /> */}
      <AppointmentDetailsDisplay />
      <div className={pageBottom}>
        <span className={textSize}>
          Thank you for trusting us with your repair needs.
        </span>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  mapFlex: {
    flex: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      flexDirection: 'row-reverse',
      paddingBottom: 20
    }
  },
  flexHolder: {
    margin: '0 auto',
    maxWidth: 330
  },
  techLocationHeader: {
    fontWeight:700
  },
  hideButton: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  showButton: {
    fontFamily: 'apercu pro !important',
    display: 'none',
    marginBottom: 30,
    marginTop: 15,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  pageBottom: {
    borderTop: '1px solid #D5D6DA',
    marginTop: 40,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: 'apercu pro'
  },
  mapContainer: {
    height: 309,
    [theme.breakpoints.up('sm')]: {
      height: 325
    }
  },
  mapEl: {
    height: '100%',
    borderRadius: '6px 6px 0px 0px',
    fontFamily: 'apercu pro'
  },
  textSize:{
    fontSize:16,
    fontWeight:400,
    fontFamily: 'apercu pro',
    fontStyle:'normal'
  }
}));
