import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { ServiceProviderDetails } from '../model';

export function DepartedDisplay(props: ServiceProviderDetails) {
  const classes = useStyles(props);
  const { container, bottomText, textSize,textSizeCenter } = classes;
  const { serviceProviderName, serviceProviderPhone } = props;

  return (
    <Container className={container}>
      <p className={textSize} >
        You will receive an email with next steps about any follow up
        appointments needed.
      </p>
      <p className={textSize}>
        If you have any questions, please contact{' '}
        {serviceProviderName} at <span>{serviceProviderPhone}</span>.
      </p>
      <p className={textSizeCenter} >
        Thank you for trusting us with your repair needs.
      </p>
      <p  className={bottomText}>
        <p style={{fontWeight:700}}>Your opinion matters.</p> We will email you a survey asking
        about our service
      </p>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily:'apercu pro !important',
    '& h3': {
      fontWeight: 'normal',
      textAlign: 'left'
    },
    '& h4': {
      padding: 15
    },
    '& span': {
      fontWeight: 'bold'
    }
  },
  bottomText: {
    paddingTop: 30,
    borderTop: '1px solid #D5D6DA',
    '& span': {
      fontWeight: 'bold',
      display: 'block'
    },
    fontWeight:400,
    fontSize:16,
    lineHeight:'130%',
    fontFamily:'apercu pro !important',
    fontStyle:'normal'
  },
  textSize:{
    fontSize:16,
    fontWeight:400,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'left'
  },
  textSizeCenter:{
    fontSize:16,
    fontWeight:400,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'center'
  }
}));
