import * as React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import defaultTechnicianImage from '../images/default-tech-image.svg';

export function TechDetailsDisplay() {
  const classes = useStyles();
  const { photo, container, badgeLink,textColor, textSize} = classes;

  const techDetails = useSelector((state: RootState) => state.tmt.techDetails);

  const {
    technicianImage,
    technicianName,
    badgeNumber,
    badgeExpiration,
    checkBadgeLink,
    technicianMobileNumber
  } = techDetails;

  const formatMobileNumberUS = (phoneNumber: string) => {
    if (!phoneNumber || typeof phoneNumber !== 'string') return null;
    const match = phoneNumber
      .trim()
      .replace(/\D/g, '')
      .match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  return (
    <div className={container}>
      <p style={{fontWeight:700}}>Your tech</p><br/>
      <img
        src={technicianImage ? technicianImage : defaultTechnicianImage}
        alt="Photo of technician"
        className={photo}
      />
      <span>Your certified technician</span>
      <span className={textSize}>{technicianName}</span>

      {technicianMobileNumber && (
        <>
          <span className={textColor}>Mobile Number&nbsp;<Link href={`tel:${technicianMobileNumber}`}>
            {formatMobileNumberUS(technicianMobileNumber)}
          </Link></span>
          
        </>
      )}
      {badgeNumber && (
        <>
          <span className={textColor}>Badge Number&nbsp;<span>{badgeNumber}</span></span>
        </>
      )}
      {badgeExpiration && (
        <>
          <span className={textColor}>Expiration&nbsp;<span>{badgeExpiration}</span></span>
          
        </>
      )}
      {checkBadgeLink && (
        <Link
          href={checkBadgeLink}
          className={badgeLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Verify at checkmybadge.com
        </Link>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  photo: {
    width: '150px !important',
    height: '150px !important',
    borderRadius: '50%',
    marginBottom: 10,
    border:'1px solid #C4C4C4',
    backgroundColor:'#C4C4C4'
  },
  container: {
    '& h3': {
      padding: '20px 0',
      fontWeight:700,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 0
      }
    },
    '& h4': {
      fontWeight: 'normal',
      fontSize: 16,
      padding: '5px 0 10px 0'
    },
    '& p': {
      fontWeight: 600,
      fontSize: '16px !important',
      marginTop: 10,
      marginBottom: 5
    },
    '& span': {
      display: 'block',
      fontWeight: 300,
      fontSize: '16px !important'
    }
  },
  badgeLink: {
    fontWeight: 'normal',
    fontSize: '14px !important',
    color: '#8223D2 !important',
    margin: '20px 0',
    display: 'block',
  },
  textColor : {
    color:'#000',
    fontWeight:400
  },
  textSize:{
    fontSize:16,
    fontWeight:400,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'center'
  },
  textSizeBold:{
    fontSize:16,
    fontWeight:700,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'center'
  },
  textSpan:{
    display: 'block',
      fontWeight: 300,
      fontSize: '16px !important'
  }
}));