import { History } from 'history';
import { combineReducers } from 'redux';
import { TMTState } from '../model';
import * as tmtReducer from './tmt';

export interface RootState {
  tmt: TMTState;
}

const combine = (history: History) =>
  combineReducers({
    ...tmtReducer
  });

export default combine