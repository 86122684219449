import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import linkedIn from '../images/linkedin.svg';
import email from '../images/email.svg';

export function Footer() {
  const classes = useStyles();
  const { footer, conatinerDiv,footerHeading,leftDiv,rightDiv,imageSize,textTop,textColor} = classes;
  return (
    <footer className={footer}>
    <div className={footerHeading}>asurion</div>
    <div className={conatinerDiv}>
      <div className={leftDiv}>© Asurion 1992 - 2020. All rights reserved.</div>
      <div>
          <div>
            <Link href='https://www.facebook.com/ServiceBench1' target='_blank'><img className={imageSize} src={facebook} alt="1"/></Link>&nbsp;&nbsp;&nbsp;&nbsp;
            <Link href='https://twitter.com/servicebench1' target='_blank'><img className={imageSize} src={twitter} alt="2"/></Link>&nbsp;&nbsp;&nbsp;&nbsp;
            <Link href='http://www.linkedin.com/company/32718?trk=prof-0-ovw-curr_pos' target='_blank'><img className={imageSize} src={linkedIn} alt="3"/></Link>&nbsp;&nbsp;&nbsp;&nbsp;
            <Link href='https://www.asurion.com/servicebench/#helpmodal' target='_blank'><img className={imageSize} src={email} alt="4"/><span><div className={textTop}>Contact us</div></span></Link>
          </div>
      </div>
      <div className={rightDiv}>
      <Link className={textColor}
            href="https://www.asurion.com/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer">
          Terms of Use
          </Link>
          <Link className={textColor}
            href="https://www.asurion.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer">
          Privacy Policy
          </Link>
      </div>
    </div>
    </footer>
  );
}

const useStyles = makeStyles(() => ({
  footer: {
    fontFamily:'apercu pro !important',
    width: '100%',
    background: '#FFFFFF',
    padding: '20px 0',
    border:'1px solid #D5D6DA',
    position:'relative',
    left:0,
    bottom:0,
    right:0,
    marginTop:20
  },
  footerLinks: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 400,
    margin: '0 auto'
  },
  copyright: {
    fontWeight: 300,
    fontSize: 16,
    paddingTop: 20
  },
  conatinerDiv: {
    display:'flex',
    flexDirection:'row',
    flexWrap: 'nowrap',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  margin:20,
  fontWeight:400,
  alignItems:'center'
  },
  footerHeading: {
    fontSize:24,
    float:'left',
    marginLeft:20,
    marginTop:-15,
    fontWeight:'bold'
  },
  leftDiv: {
    float:'left'
  },
  rightDiv: {
    float : 'right'
  },
  imageSize: {
    width:25,
    height:25,
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center'
  },
  textTop: {
    marginTop : 3,
    marginLeft: 10,
    color:'#000',
    float:'right'
  },
  textColor: {
    color:'#000',
    textDecoration:'underline !important',
    marginLeft:10
  }
}));