import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { ServiceProviderDetails } from '../model';

export function ErrorDisplay(props: ServiceProviderDetails) {
  const classes = useStyles(props);
  const { serviceProviderName, serviceProviderPhone } = props;
  const { container,textSize } = classes;

  const setMessageText = () => {
    if (!serviceProviderName && !serviceProviderPhone) {
      return 'your service provider';
    } else if (!serviceProviderName) {
      return <span>{serviceProviderPhone}</span>;
    } else if (!serviceProviderPhone) {
      return serviceProviderName;
    } else {
      return (
        <>
          {serviceProviderName} at <span>{serviceProviderPhone}</span>
        </>
      );
    }
  };
  return (
    <Container className={container}>
      <h5 className={textSize}>
        Please wait and try again in 5 minutes.
        <br />
        If the problem continues, please call {setMessageText()}. We’re happy to
        help answer questions about your appointment.
      </h5>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& h3': {
      fontFamily: 'apercu pro !important',
      fontWeight: 400,
      textAlign: 'left',
      paddingBottom: 20,
      fontSize: 20,
      lineHeight: '25px'
    },
    '& span': {
      fontWeight: 'bold'
    }
  },
  textSize:{
    fontFamily: 'apercu pro !important',
      fontWeight: 400,
      textAlign: 'left',
      paddingBottom: 20,
      fontSize: 16,
      lineHeight: '25px'
  }
}));
