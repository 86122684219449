import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';

export function AppointmentDetailsDisplay() {
  const classes = useStyles();
  const { container, header, border,footerTitleHead} = classes;

  const serviceProviderDetails = useSelector(
    (state: RootState) => state.tmt.serviceProviderDetails
  );

  const { serviceProviderName, serviceProviderPhone } = serviceProviderDetails;

  const appointmentDetails = useSelector(
    (state: RootState) => state.tmt.appointmentDetails
  );

  const {
    appointmentDate,
    appointmentTime,
    appointmentLocation
  } = appointmentDetails;

  return (
    <div className={container}>
      <div>
        <span className={footerTitleHead} >Appointment details</span>
      </div><br/>
      <div>
        <div style={{display:'flex'}}>
            <div style={{width:'50%'}}>
              <span style={{fontWeight:700,fontSize:18}}>
                When:
              </span><br/>
              <span >{appointmentDate}</span><br/>
              <span >{appointmentTime}</span>
            </div>
            <div style={{width:'50%'}}>
              <span style={{fontWeight:700,fontSize:18}}>
                Where:
              </span><br/>
              <span >{appointmentLocation}</span>
            </div>
          </div>
      </div><br/>
      <div style={{borderTop:'1px solid #D5D6DA'}}>
        <div style={{marginTop:'10px'}}>
          <span  className={`${header} ${border}`}>
            Service Provider:
          </span><br/>
          <span >{serviceProviderName}</span><br/>
          <span >{serviceProviderPhone}</span>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#F0F0F5',
    borderRadius: 6,
    padding: '30px 16px',
    textAlign: 'left',
    '& h3': {
      fontWeight: 'normal',
      fontSize: 20
    },
    '& p': {
      margin: 0,
      fontSize: 17,
      maxWidth: 205
    },
    '& .MuiGrid-container': {
      maxWidth: 500
    }
  },
  header: {
    paddingTop: 20,
    fontWeight:700,
    fontSize:'18px !important'
  },
  border: {
    marginTop: '30px !important'
  },
  footerTitleHead: {
    fontWeight:400,
    color:'#000',
    fontSize:'24px !important'
  },
  textSize: {
    fontWeight:400,
    fontSize:'18px !important',

  }
}));
