import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Theme } from '@material-ui/core/styles';
import mapIcon from '../images/map-icon-rebrand.svg';
import { RootState } from '../reducers';

export function TechArrivalDisplay() {
  const classes = useStyles();
  const {colorBar,textSize,textSizeBold,imageCenter,centerContainer } = classes;

  const techArrivalTime = useSelector(
    (state: RootState) => state.tmt.technicianStatusDetails.techArrivalTime
  );

  return (
    <div className={centerContainer}>
      <div className={colorBar}></div><br/>
      <div style={{padding:'40px'}}>
        <img className={imageCenter}  src={mapIcon} alt="technician has arrived" /><br/><br/>
        <p className={textSizeBold}>Your technician has arrived!</p>
        <p className={textSize}>
          <p>Technician arrival time:</p>{techArrivalTime}
        </p>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  colorBar: {
    width: '100%',
    background: '#8223D2',
    borderRadius: '6px 6px 0px 0px',
    height: 35
  },
  textSize:{
    fontSize:16,
    fontWeight:400,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'center'
  },
  textSizeBold:{
    fontSize:18,
    fontWeight:700,
    fontFamily:'apercu pro !important',
    fontStyle:'normal',
    textAlign:'center'
  },
  imageCenter:{
    marginTop:'20px'
  },
  centerContainer: {
    fontFamily: 'apercu pro !important',
    padding: '0px !important',
    maxWidth: 650,
    margin: '0 auto',
    border: '1px solid #D5D6DA !important',
    marginTop:20,
    backgroundColor:'#F0F0F5',
    borderRadius: 6,
    [theme.breakpoints.up('sm')]: {
      padding: '10px 30px'
    }
  },
}));
