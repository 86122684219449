import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export function RefreshButton(props: any) {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.refreshButton} ${props.className}`}
      onClick={() => console.log('Refresh state')}
    >
      Refresh page
    </Button>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  refreshButton: {
    border: '1px solid #8223D2',
    margin: '20px auto',
    borderRadius: 6,
    color: '#377A80',
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 400,
    padding: '7px 74px'
  }
}));
