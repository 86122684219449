// import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import * as React from 'react';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  typography: {
    fontFamily: 'apercu pro !important'
  } ,
  palette: {
    primary: {
      light: '#E5E5E5',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5e50',
      main: '#e41e26',
      dark: '#a90000',
      contrastText: '#fff'
    }
  }
  ,
  overrides: {
    MuiTypography: {
      h1: {
        margin: '20px 0 30px 0 !important',
        color: '#000000 !important',
        fontWeight: 300,
        fontSize: '16px !important',
        [breakpoints.up('sm')]: {
          fontSize: '24px !important'
        }
      },
      h2: {
        color: '#000000 !important',
        fontWeight: 600,
        fontSize: 20
      },
      h3: {
        color: '#000000 !important',
        fontWeight: 700,
        fontSize: '16px !important',
        fontFamily:'Apercu pro !important'
      },
      h4: {
        fontWeight: 'normal',
        fontSize: '16px !important',
        fontFamily:'apercu pro !important'
      },
      h5: {
        color: '#000000 !important',
        fontWeight: 'bold',
        fontSize: '16px !important',
        fontFamily:'apercu pro !important'
      },
      body1: {
        color: '#000000 !important',
        fontSize: '16px !important',
        fontFamily:'apercu pro !important'
      },
      body2: {
        color: '#000000 !important',
        fontSize: '16px !important',
        fontFamily:'apercu pro !important'
      }
    }
  }
});

export function withRoot(Component: any) {
  function WithRoot(props: object) {
    return (
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}