import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import progressBarRebrandImage from '../images/progress-bar-rebrand.svg';

export function LoadingScreen() {
  const classes = useStyles();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
        return 0;
        }
        setInterval(progress, 100);
        setInterval(progress, 100);
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 100);
    return () => {
      setCompleted(100);
      clearInterval(timer);
    };
  }, []);

  const { container, topText } = classes;

  return (
    <div className={container}>
      <Typography variant="h2" className={topText}>
        Sit tight! We’re locating your technician.
      </Typography>
      <br/><br/>
      <img src={progressBarRebrandImage} alt="process"/>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    loadingBar: {
      height: 8,
      width: '35%',
      margin: '15px auto',
      backgroundColor: '#E0E0E0',
      color: '#8223D2 !important',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#8223D2'
      }
    },
    topText: {
      fontWeight: 300,
      fontSize: 22
    },
    container: {
      marginTop: 60,
      marginBottom: 150
    }
  })
);