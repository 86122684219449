import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { TechnicianStatus } from '../model';

function TechProgressText(props: TechnicianStatus) {
  const classes = useStyles();

  const setStatusText = (status: string) => {
    switch (status) {
      case 'EnRoute':
        return 'Your technician is en-route.';
      case 'Arrived':
        return 'Your technician has arrived!';
      case 'Departed':
        return 'Your technician has departed.';
      case 'Canceled':
        return 'Your repair appointment was canceled.';
      default:
        return 'Sorry, an error occurred.';
    }
  };

  const { techStatus } = props;
  const { techStatusContainer,textColor } = classes;

  return (
    <div className={techStatusContainer}>
      <span className={textColor}>
        {setStatusText(techStatus)}
      </span>
    </div>
  );
}

export default TechProgressText;

const useStyles = makeStyles((theme: Theme) => ({
  techStatusContainer: {
    fontFamily: 'apercu pro !important',
    margin: '0 auto',
    padding: '0px 16px 25px 10px',
    textAlign: 'center',
    fontWeight:700,
    fontSize:24,
    width: '100%',
    borderBottom:'1px solid #D5D6DA',
    '& h2': {
      borderBottom: '1px solid #D5D6DA',
      paddingBottom: 25
    },
    '& h3': {
      paddingTop: 20
    },
    '& h5': {
      borderBottom: '2px solid #F0F0F5',
      paddingBottom: 25,
      fontWeight:700
    },
    '& h4': {
      paddingTop: 20
    }
  },

  textColor:{
    fontWeight:700,
    fontSize:20,
    lineHeight:'130%',
    color:'#000',
    fontFamily:'apercu pro !important'
  }
}));
