/**
 * Created by toni on 12.03.2017.
 */
import { Reducer } from 'redux';
import { TMTActions, TMTAction } from '../model';

type Handlers<S> = {
  [action in TMTActions]: (state: S, action: TMTAction) => S;
};

export default function createReducer<S>(
  initialState: S,
  handlers: Handlers<S>
): Reducer<S> {
  const r = (state: S = initialState, action: TMTAction): S => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action) as S;
    } else {
      return state;
    }
  };

  return r as Reducer<S>;
}
