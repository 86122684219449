import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TechnicianStatus } from '../model';

export function AppWarning(props: TechnicianStatus) {
  const classes = useStyles(props);
  const { appWarning, alert } = classes;
  const { techStatus } = props;

  if (techStatus === 'Canceled' || !techStatus) {
    return (
      <Snackbar
        className={appWarning}
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert icon={false} severity="error" className={alert}>
          {techStatus === 'Canceled'
            ? 'No scheduled repair'
            : !techStatus
            ? 'Sorry, an error occured. Please try again later.'
            : ''}
        </Alert>
      </Snackbar>
    );
  } else {
    return null;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  appWarning: {
    display: 'block',
    top: 72,
    width: '100%',
    position: 'absolute',
    left: 0,
    [theme.breakpoints.up('sm')]: {
      left: '50%'
    }
  },
  alert: {
    justifyContent: 'left',
    color: '#FFF !important',
    fontSize: 16,
    backgroundColor: '#DB1B1B !important',
    width:'100%',
    marginTop:-10,
    fontFamily:'apercu pro !important'
  }
}));
