import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { ServiceProviderDetails } from '../model';

export function CancelDisplay(props: ServiceProviderDetails) {
  const classes = useStyles(props);
  const { serviceProviderName, serviceProviderPhone } = props;
  const { container } = classes;

  return (
    <Container className={container}>
      <span>
        If you believe you received this message in error, please call{' '}
        {serviceProviderName} at <span>{serviceProviderPhone}</span>.
      </span>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& h3': {
      fontWeight: 'normal',
      textAlign: 'left',
      paddingBottom: 20
    },
    '& span': {
      fontWeight: 'bold'
    }
  }
}));
